import React from "react";
import PropTypes from "prop-types";
import "./PdfViewerError.scss";

export function PdfViewerError({ error, retry }) {
    return (
        <div
            className="pdf-viewer-error"
            data-qa="pdf-viewer-error"
            data-walkme="pdf-viewer-error"
        >
            {error !== "" ? (
                <span>
                    Unable to load file.
                    <br />
                    {error}
                    <br />
                    <button
                        onClick={retry}
                        className="ds-button ds-button--cat__primary ds-button--size__small"
                    >
                        Retry
                    </button>
                </span>
            ) : (
                <span>There was a problem displaying the file.</span>
            )}
        </div>
    );
}

PdfViewerError.propTypes = {
    error: PropTypes.string,
    retry: PropTypes.func
};

export default PdfViewerError;
