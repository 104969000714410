import axios from "axios";
import { store } from "../index.js";

const { DMS_BASE_API } = window.runConfig;

export function pdfHeader() {
    const accessToken = store.getState().oidc.user.access_token;

    return axios.create({
        baseURL: `${DMS_BASE_API.replace(/\/$/, "")}/`,
        withCredentials: false,
        responseType: "arraybuffer",
        headers: {
            Accept: "application/pdf",
            "Content-Type": "application/pdf",
            Authorization: `Bearer ${accessToken}`
        }
    });
}

/* eslint-disable no-undef */
export default () => {
    const accessToken = store.getState().oidc.user.access_token;

    return axios.create({
        baseURL: `${DMS_BASE_API.replace(/\/$/, "")}/`,
        withCredentials: false,
        timeout: 20_000, //ms
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });
};
