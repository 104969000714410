import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./SearchInput.scss";

function SearchInput(props) {
    const { onSubmit, onChange, query } = props;
    let searchInput = query;

    useEffect(() => {
        searchInput.focus();
        if (query) {
            searchInput.value = query;
        }
    }, [searchInput, query]);

    return (
        <div
            className="search-input"
            data-qa="search-input"
            data-walkme="search-input"
        >
            <form onSubmit={onSubmit} autoComplete="off">
                <div className="ds-input-group">
                    <input
                        className="ds-input ds-input--size__small"
                        type="search"
                        name="searchInput"
                        placeholder="Loan number or name"
                        ref={input => {
                            searchInput = input;
                        }}
                        data-qa="search-input-field"
                        data-walkme="search-input-field"
                        aria-label="search-input-field"
                        onChange={onChange}
                    />
                    <div className="ds-input-group-append">
                        <button
                            className="ds-button ds-button--cat__primary ds-button--size__small"
                            title="Search For Loan"
                            type="submit"
                            data-qa="search-input-submit"
                            data-walkme="search-input-submit"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

SearchInput.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    query: PropTypes.string
};

export default SearchInput;
