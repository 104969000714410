import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ZoomInBtn = ({ zoomInBtnClick }) => {
    return (
        <button
            id="amc-viewer--zoom-in"
            className="ds-control-button ds-control-button--cat__secondary"
            data-qa="zoom-in"
            data-walkme="zoom-in"
            aria-label="zoom-in"
            onClick={zoomInBtnClick}
        >
            <FontAwesomeIcon icon={["fas", "plus"]} />
        </button>
    );
};

export default ZoomInBtn;
