import * as types from "./actionTypes";
import * as currentLoanService from "../../services/currentLoanService";
import createAPIErrorMessage from "../../utils/createAPIErrorMessage";
import { filterPermissionList } from "../../utils/helpers";

export function clearCurrentLoan() {
    return {
        type: types.CLEAR_CURRENT_LOAN
    };
}

export function setCurrentLoandError(error) {
    return {
        type: types.SET_CURRENT_LOAN_ERROR,
        error: createAPIErrorMessage(error)
    };
}

export function setCurrentLoan(loan) {
    return {
        type: types.SET_CURRENT_LOAN,
        loan
    };
}

export function getCurrentLoan() {
    return {
        type: types.GET_CURRENT_LOAN
    };
}

export function setCurrentLoanByLoanNumber(folderId) {
    return function(dispatch, getState) {
        const loans = getState().search.loans;
        const currentLoan = loans.find(
            loan => loan.FolderId.toString() === folderId
        );
        if (currentLoan) {
            return dispatch(setCurrentLoan(currentLoan));
        } else {
            return dispatch(getCurrentLoanByID(folderId));
        }
    };
}

export function getCurrentLoanByID(loanFolderId) {
    return function(dispatch) {
        dispatch(getCurrentLoan());

        return currentLoanService
            .getLoanByLoanFolderId(loanFolderId)
            .then(results => {
                dispatch(setCurrentLoan(results));
            })
            .catch(error => {
                dispatch(setCurrentLoandError(error));
                throw error;
            });
    };
}

/******* Documents ******/

export function loadDocuments() {
    return { type: types.LOAD_DOCUMENTS };
}

export function loadDocumentsSuccess(documents) {
    return { type: types.LOAD_DOCUMENTS_SUCCESS, documents };
}

export function loadDocumentsError(error) {
    return {
        type: types.LOAD_DOCUMENTS_ERROR,
        error: createAPIErrorMessage(error)
    };
}

export function currentSelectedDocument(document) {
    return { type: types.SELECTED_DOCUMENT, document };
}
/**
 * Thunk async callback for fetching documents given a folderId
 * @param {[number]} folderId
 *
 */
export function loadDocumentsByLoanFolderId(folderId) {
    return function(dispatch) {
        dispatch(loadDocuments());

        return currentLoanService
            .getLoanDocuments(folderId)
            .then(res => {
                const filteredDocumentList = filterPermissionList(
                    res.data.Items
                );
                dispatch(loadDocumentsSuccess(filteredDocumentList));
            })
            .catch(error => {
                dispatch(loadDocumentsError(error));
                throw error;
            });
    };
}

/******* PDF Files ******/

export function loadFile() {
    return { type: types.LOAD_FILE };
}

export function loadFileError(error) {
    return {
        type: types.LOAD_FILE_ERROR,
        error: createAPIErrorMessage(error)
    };
}

export function loadFileSuccess() {
    return {
        type: types.LOAD_FILE_SUCCESS
    };
}

export function getFileForSelectedDocument(documentId) {
    return function(dispatch) {
        dispatch(loadFile());
        return currentLoanService
            .getFileForDocumentId(documentId)
            .then(fileData => {
                dispatch(loadFileSuccess());
                return fileData;
            })
            .catch(error => {
                dispatch(loadFileError(error));
                throw error;
            });
    };
}
