import React from "react";
import PropTypes from "prop-types";
import SearchResultsTable from "../SearchResultsTable/SearchResultsTable";
import SearchResultsTableSkeleton from "../SearchResultsTable/SearchResultsTableSkeleton";
import LoadingIndicator from "../../../LoadingIndicator/LoadingIndicator";
import "./SearchResults.scss";

function SearchResults({ loading, results, query, errorMessage, onOpen }) {
    return (
        <div className="search-results">
            <div className="search-results__status">
                {loading && (
                    <LoadingIndicator
                        loadingMessage="Searching for Loans"
                        styles={{ marginTop: -5 }}
                    />
                )}
                {errorMessage && (
                    <div
                        className="search-results__error"
                        data-qa="search-results-error"
                        data-walkme="search-results-error"
                    >
                        Sorry, we are unable to complete your search.
                        <br />
                        {errorMessage}
                    </div>
                )}
                {query !== "" && results.length === 0 && errorMessage === null && (
                    <div
                        className="search-results__not-found"
                        data-qa="search-results--not-found"
                        data-walkme="search-results--not-found"
                    >
                        No results found for{" "}
                        <span className="search-results__query-value">
                            {query}
                        </span>
                    </div>
                )}
            </div>

            {results.length > 0 ? (
                <SearchResultsTable results={results} onOpen={onOpen} />
            ) : (
                <SearchResultsTableSkeleton />
            )}
        </div>
    );
}

SearchResults.propTypes = {
    loading: PropTypes.bool,
    results: PropTypes.array.isRequired,
    query: PropTypes.string,
    errorMessage: PropTypes.string,
    onOpen: PropTypes.func
};

LoadingIndicator.defaultProps = {
    loading: false
};

export default SearchResults;
