import * as types from "../actions/actionTypes";
import initialState from "../intialState";

const currentUser = localStorage.getItem("current_user");
const initState = currentUser
    ? {
          isLoggedIn: true,
          user: JSON.parse(currentUser),
      }
    : initialState.authentication;

export default function auth(state = initState, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                isLoggedIn: false,
                user: initState.user,
            };
        case types.LOGIN_SUCCESS:
            const user = currentUser
                ? JSON.parse(currentUser)
                : {
                      fullName: action.user.fullName,
                      userId: action.user.userId,
                      email: action.user.email,
                      isAuthorized: action.user.isAuthorized,
                  };
            return {
                isLoggedIn: true,
                user,
            };
        case types.LOGIN_FAIL:
            return {
                isLoggedIn: false,
                user: initState.user,
            };
        case types.LOGOUT:
            return {
                isLoggedIn: false,
                user: initState.user,
            };
        default:
            return state;
    }
}
