import apiClient from "./apiClient";
import userManager from "../utils/userManager";
import Uploader from "simple-uploader.js";
import { uploadProgressChange } from "../redux/actions/uploadActions";

const { DMS_BASE_API } = window.runConfig;

export function createReceivedItem(mailItemRequestData) {
    return apiClient()
        .post(`${DMS_BASE_API}/api/v1/ReceivedMailItems`, mailItemRequestData)
        .then(result => {
            return {
                mailItem: result.data
            };
        });
}

export function fileUploadInChunks(file, dispatch) {
    return new Promise((resolve, reject) => {
        /** The API calls to upload the file chunks will account for is 95% of the total upload progress. */
        const documentGuid = generateGuid();
        const fileUploadPortion = 95;
        let currentPercent = 0;
        let fileSize = null;

        const uploader = new Uploader({
            target: `${DMS_BASE_API}/api/v1/Upload/upload-chunks`,
            testChunks: true,
            forceChunkSize: true,
            generateUniqueIdentifier: function() {
                return documentGuid;
            },
            singleFile: true,
            simultaneousUploads: 1,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getAccessToken()}`
            }
        });

        const updateUploadProgress = () => {
            const percentUploaded = Math.floor(
                (uploader.sizeUploaded() / fileSize) * fileUploadPortion
            );
            const incrementUploaded = percentUploaded - currentPercent;
            currentPercent = percentUploaded;
            dispatch(uploadProgressChange(incrementUploaded));
        };

        uploader.on("fileAdded", file => {
            fileSize = file.size;
        });

        uploader.on("fileProgress", updateUploadProgress);

        uploader.on("fileSuccess", () => {
            resolve(documentGuid);
        });

        uploader.on("fileError", (rootFile, file, message, chunk) => {
            reject(Error("File Error"));
        });

        uploader.addFile(file);
        uploader.upload();
    });
}

/** From https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript */
function generateGuid() {
    var d = new Date().getTime(); //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported

    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
}

async function getAccessToken() {
    return await userManager.getUser().access_token;
}
