import * as types from "../actions/actionTypes";
import initialState from "../intialState";

export default function uploadReducer(state = initialState.upload, action) {
    switch (action.type) {
        case types.UPLOAD_START:
            return {
                ...state,
                status: "IN_PROGRESS",
                percentComplete: 0,
                filename: action.filename,
                documentType: action.documentType,
                error: null
            };
        case types.UPLOAD_PROGRESS_CHANGE:
            return {
                ...state,
                percentComplete: state.percentComplete + action.percent
            };
        case types.UPLOAD_SUCCESS:
            return {
                ...state,
                status: "SUCCESS",
                percentComplete: 100
            };
        case types.UPLOAD_ERROR:
            return {
                ...state,
                status: "ERROR",
                percentComplete: null,
                error: action.error
            };
        case types.UPLOAD_CLEAR:
            return {
                ...state,
                ...initialState.upload
            };
        default:
            return state;
    }
}
