import React from "react";
import PropTypes from "prop-types";

export function LoanError({ error, retry, cancel }) {
    return (
        <main className="amc-main">
            <div
                className="loan-error"
                data-qa="loan-error"
                data-walkme="loan-error"
            >
                <div className="loan-error__message">
                    Unable to load loan.
                    <br />
                    {error}
                </div>
                <div className="loan-error__controls">
                    <button
                        className="ds-button ds-button--cat__cancel ds-button--background__borderless ds-button--size__small"
                        onClick={cancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="ds-button ds-button--cat__primary ds-button--size__small"
                        onClick={retry}
                    >
                        Retry
                    </button>
                </div>
            </div>
        </main>
    );
}

LoanError.propTypes = {
    error: PropTypes.string,
    retry: PropTypes.func,
    cancel: PropTypes.func
};

export default LoanError;
