import React from "react";
import "./FileNotFound.scss";
import { Link } from "react-router-dom";

const FileNotFound = () => {
    return (
        <main
            className="amc-main amc-404--container"
            data-qa="amc-file-not-found"
            data-walkme="amc-file-not-found"
        >
            <h2>404: Page not found</h2>
            <p className="amc-404-subtitle">
                We could not find the page you were looking for.
            </p>
            <p>Try these:</p>
            <Link to="/">Home</Link>
        </main>
    );
};

export default FileNotFound;
