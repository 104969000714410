// React Redux
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import AmcHeader from "./components/AmcHeader/AmcHeader";
import AmcFooter from "./components/AmcFooter/AmcFooter";

// Layout/Views
import SearchLoan from "./components/layout/SearchLoan/SearchLoan";
import Loan from "./components/layout/Loan/Loan";
import Landing from "./components/layout/Landing/Landing";
import PageNotFound from "./components/layout/FileNotFound/FileNotFound";
import UnAuthorized from "./components/layout/UnAuthorized/UnAuthorized";

import * as userActions from "./redux/actions/userActions";

import { PrivateRoute } from "./components/PrivateRoute";

// Routing
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCaretDown,
    faCaretLeft,
    faPlus,
    faMinus,
    faRedo,
    faUndo,
} from "@fortawesome/free-solid-svg-icons";

import CallbackPage from "./components/layout/CallbackPage/CallbackPage";
import userManager from "./utils/userManager";

import "./styles/styles.scss";

library.add(faCaretDown, faCaretLeft, faPlus, faMinus, faRedo, faUndo);

const history = createBrowserHistory();

export function AmcApp({ actions, authentication }) {
    const isLoggedIn =
        authentication.isLoggedIn && authentication.user.isAuthorized;
    useEffect(() => {
        document.title = "DocHub";
    }, []);

    useEffect(() => {
        const checkToken = async () => {
            const userInfo = await userManager.getUser();
            userInfo === null
                ? userManager.signinRedirect()
                : actions.loginUser();
        };
        checkToken();
    }, [actions]);

    const onLoginSubmit = () => {
        userManager.signinRedirect();
    };

    const onLogout = () => {
        history.push("/landing");
        actions.logoutUser();
    };

    return (
        <Router history={history}>
            <div className="amc-app--wrapper">
                <AmcHeader
                    onLogin={onLoginSubmit}
                    onLogout={onLogout}
                    username={`${authentication.user.fullName}`}
                />

                <Switch>
                    <Route path="/landing">
                        <Landing />
                    </Route>

                    {/* <Route exact path="/">
                        { !authentication.isLoggedIn ? <Landing /> : null }
                    </Route> */}

                    <Route exact path="/">
                        {!isLoggedIn ? <UnAuthorized /> : <SearchLoan />}
                    </Route>

                    <PrivateRoute
                        path="/search"
                        isLoggedIn={isLoggedIn}
                        component={SearchLoan}
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/loan/:LoanNumber"
                        isLoggedIn={isLoggedIn}
                        component={Loan}
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/loan"
                        isLoggedIn={isLoggedIn}
                        component={Loan}
                    ></PrivateRoute>

                    <Route path="/callback" component={CallbackPage} />

                    <Route component={PageNotFound}></Route>
                </Switch>

                <AmcFooter />
            </div>
        </Router>
    );
}

function mapStateToProps(state) {
    return { authentication: state.authentication, oidc: state.oidc };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loginUser: bindActionCreators(userActions.loginUser, dispatch),
            logoutUser: bindActionCreators(userActions.logoutUser, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AmcApp);
