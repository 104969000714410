export default {
    search: {
        query: "",
        loans: [],
        error: null,
    },
    currentLoan: {
        loan: null,
        loadingLoan: false,
        loadingLoanError: "",
        documents: [],
        loadingFile: false,
        loadingFileError: "",
        selectedDocument: null,
        loadingDocuments: false,
        loadingDocumentsError: "",
    },
    authentication: {
        isLoggedIn: false,
        user: {
            id: null,
            fullName: "",
            email: "",
            isAuthorized: false,
        },
    },
    upload: {
        status: "",
        percentComplete: null,
        filename: "",
        documentType: null,
        error: null,
    },
};
