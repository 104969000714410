import * as types from "./actionTypes";
import * as searchApi from "../../services/searchService";
import createAPIErrorMessage from "../../utils/createAPIErrorMessage";

export function searchLoan() {
    return { type: types.SEARCH_LOAN };
}

export function resetLoanSearch() {
    return {
        type: types.RESET_LOAN_SEARCH,
        loans: [],
        query: ""
    };
}

export function loadLoansError(error) {
    return {
        type: types.SEARCH_LOAN_ERROR,
        error: createAPIErrorMessage(error)
    };
}

export function loadLoansSuccess({ loans, query }) {
    return {
        type: types.SEARCH_LOAN_SUCCESS,
        loans,
        query
    };
}

export function loadLoans(query) {
    return function(dispatch) {
        dispatch(searchLoan());
        return searchApi
            .getLoans(query)
            .then(results => {
                dispatch(loadLoansSuccess(results));
            })
            .catch(error => {
                dispatch(loadLoansError(error));
                throw error;
            });
    };
}

export function resetLoans() {
    return function(dispatch) {
        dispatch(searchLoan());
        dispatch(resetLoanSearch());
    };
}
