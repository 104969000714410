import React from "react";
import PropTypes from "prop-types";
import DocumentDownloadBtn from "../DocumentDownloadBtn/DocumentDownloadBtn";
import RotateButton from "../RotateButton/RotateButton";
import ZoomInBtn from "../ZoomInBtn/ZoomInBtn";
import ZoomOutBtn from "../ZoomOutBtn/ZoomOutBtn";
import "./PdfViewerControls.scss";

const PdfViewerControls = ({
    scale,
    onScaleChange,
    onRotationChange,
    onDownloadDocument
}) => {
    const handleRotateButtonClick = button => {
        button.direction === "right"
            ? onRotationChange(1)
            : onRotationChange(-1);
    };

    const handleZoomInBtnClick = () => {
        if (scale < 3.0) {
            const newScale = scale + scale * 0.5;
            onScaleChange(newScale);
        }
    };

    const handleZoomOutBtnClick = () => {
        if (scale > 0.25) {
            const newScale = scale - scale * 0.5;
            onScaleChange(newScale);
        }
    };

    return (
        <div className="pdf-viewer--controls">
            <div className="action-group">
                <ZoomInBtn zoomInBtnClick={handleZoomInBtnClick} />
                <ZoomOutBtn zoomOutBtnClick={handleZoomOutBtnClick} />
            </div>

            <div className="action-group">
                <RotateButton
                    onClick={handleRotateButtonClick}
                    icon="undo"
                    direction="left"
                />
                <RotateButton
                    onClick={handleRotateButtonClick}
                    icon="redo"
                    direction="right"
                />
            </div>

            <div className="action-group">
                <DocumentDownloadBtn downloadBtnClick={onDownloadDocument} />
            </div>
        </div>
    );
};

PdfViewerControls.propTypes = {
    /** Current zoom scale */
    scale: PropTypes.number,
    onScaleChange: PropTypes.func,
    onRotationChange: PropTypes.func,
    onDownloadDocument: PropTypes.func
};

export default PdfViewerControls;
