import React from "react";
import MainHeading from "../../MainHeading/MainHeading";
import UWMLogo from "../../../assets/uwm_logo.svg";
import "./Landing.scss";

function Landing() {
    return (
        <main className="amc-main amc-app--landing">
            <MainHeading />
            <div className="landing-logo">
                <img
                    src={UWMLogo}
                    alt="UWM"
                    style={{ width: 300, marginTop: 100 }}
                />
            </div>
        </main>
    );
}

export default Landing;
