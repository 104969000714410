import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as currentLoanActions from "../../../../redux/actions/currentLoanActions";
import { connect } from "react-redux";
import DocumentListLoading from "./DocumentListLoading";
import DocumentListError from "./DocumentListError";
import DocumentListTable from "./DocumentListTable/DocumentListTable";

import "./DocumentList.scss";

export function DocumentList({
    currentLoanDocuments,
    currentLoanFolderId,
    selectedDocument,
    loadingDocuments,
    loadingDocumentsError,
    actions
}) {
    useEffect(() => {
        if (currentLoanDocuments.length === 0) {
            actions.loadDocumentsByLoanFolderId(currentLoanFolderId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentLoanDocuments.length > 0 && !selectedDocument) {
            actions.currentSelectedDocument(currentLoanDocuments[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLoanDocuments]);

    const documentSelect = document => {
        actions.currentSelectedDocument(document);
    };

    const handleRowKeypress = (event, document) => {
        if (event.key === "Enter" || event.key === " ") {
            documentSelect(document);
        }
    };

    const handleRetryLoadDocuments = () => {
        actions.loadDocumentsByLoanFolderId(currentLoanFolderId);
    };

    const renderContent = () => {
        if (loadingDocuments && currentLoanDocuments.length === 0) {
            return <DocumentListLoading />;
        }

        if (loadingDocumentsError) {
            return (
                <DocumentListError
                    error={loadingDocumentsError}
                    retryLoadDocuments={handleRetryLoadDocuments}
                />
            );
        }

        return (
            <DocumentListTable
                currentLoanDocuments={currentLoanDocuments}
                selectedDocument={selectedDocument}
                rowClick={documentSelect}
                rowKeyPress={handleRowKeypress}
            />
        );
    };

    return (
        <section className="document-list">
            <h1 className="ds-section-heading">Loan documents</h1>
            <div className="document-list__wrapper">{renderContent()}</div>
        </section>
    );
}

DocumentList.propTypes = {
    currentLoanDocuments: PropTypes.array.isRequired,
    currentLoanFolderId: PropTypes.number,
    selectedDocument: PropTypes.object,
    loadingDocuments: PropTypes.bool,
    loadingDocumentsError: PropTypes.string,
    actions: PropTypes.object
};

function mapStateToProps(state) {
    return {
        currentLoanDocuments: state.currentLoan.documents,
        currentLoanFolderId: state.currentLoan.loan.FolderId,
        selectedDocument: state.currentLoan.selectedDocument,
        loadingDocuments: state.currentLoan.loadingDocuments,
        loadingDocumentsError: state.currentLoan.loadingDocumentsError
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loadDocumentsByLoanFolderId: bindActionCreators(
                currentLoanActions.loadDocumentsByLoanFolderId,
                dispatch
            ),
            currentSelectedDocument: bindActionCreators(
                currentLoanActions.currentSelectedDocument,
                dispatch
            )
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);
