import React from "react";
import PropTypes from "prop-types";
import "./UploadSuccessMessage.scss";

export function UploadSuccessMessage({ filename, onClose }) {
    const handleClose = event => {
        event.preventDefault();
        onClose();
    };

    return (
        <div className="upload-success-message">
            <div className="upload-success__text">
                The following document was successfully uploaded:
            </div>
            <div className="upload-success__document">
                <div className="document__label">Document Name:</div>
                <div className="document__filename">{filename}</div>
            </div>
            <div className="upload-success__controls">
                <button
                    className="ds-button ds-button--cat__success ds-button--size__small"
                    onClick={handleClose}
                >
                    Back to loan
                </button>
            </div>
        </div>
    );
}

UploadSuccessMessage.propTypes = {
    /** Callback to cancel upload */
    onClose: PropTypes.func,

    /** Filename of the file that has been uploaded */
    filename: PropTypes.string
};

export default UploadSuccessMessage;
