import { combineReducers } from "redux";
import search from "./searchReducer";
import authentication from "./userReducer";
import currentLoan from "./currentLoanReducer";
import upload from "./uploadReducer";
import { reducer as oidc } from "redux-oidc";

const rootReducer = combineReducers({
    search,
    currentLoan,
    authentication,
    oidc,
    upload
});

export default rootReducer;
