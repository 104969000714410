import * as types from "./actionTypes";
import * as uploadService from "../../services/uploadFileService";
import createAPIErrorMessage from "../../utils/createAPIErrorMessage";

export function uploadStart(filename, documentType) {
    return {
        filename,
        documentType,
        type: types.UPLOAD_START
    };
}

export function uploadProgressChange(percent) {
    return {
        type: types.UPLOAD_PROGRESS_CHANGE,
        percent: percent
    };
}

export function uploadSuccess() {
    return {
        type: types.UPLOAD_SUCCESS
    };
}

export function uploadError(error) {
    return {
        type: types.UPLOAD_ERROR,
        error: createAPIErrorMessage(error)
    };
}

export function uploadClear() {
    return {
        type: types.UPLOAD_CLEAR
    };
}

export function uploadFile(folderId, file, documentType) {
    return async (dispatch, getState) => {
        const userEmail = getState().authentication.user.email;

        dispatch(uploadStart(file.name, documentType));

        try {
            /**  STEP 1- Upload file and get Upload Ticket with GUID */
            let documentGuid = await uploadService.fileUploadInChunks(
                file,
                dispatch
            );

            /**  STEP 2 - Create a Received Mail Item */
            const mailItemRequestData = {
                folderId: parseInt(folderId, 10),
                subject: `File Upload - ${documentType.Name}`,
                message: `Upload of ${documentType.Name}`,
                from: userEmail,
                channel: "Desktop Upload",
                to: `${folderId}@dms.net`,
                isFiled: true,
                isUseAdr: false,
                useRecognition: false,
                createDocumentInputs: [
                    {
                        documentTypeId: documentType.DocumentTypeId,
                        documentStatusId: 1,
                        subordinateDocumentTypeId: null,
                        overrideCategoryId: null,
                        fileGuid: documentGuid,
                        fileName: `${documentType.Name}`,
                        expirationDateUTC: null,
                        comment: null,
                        tagTypeIds: []
                    }
                ]
            };

            dispatch(uploadProgressChange(2));
            await uploadService.createReceivedItem(mailItemRequestData);

            dispatch(uploadSuccess());
        } catch (error) {
            // catch errors in any of the above awaits
            dispatch(uploadError(error));
        }
    };
}
