import React from "react";

const LoanBackBtn = ({ searchBtnClick }) => {
    return (
        <div className="loan--search-button">
            <button
                className="ds-button ds-button--cat__secondary ds-button--size__small ds-button--background__ghost"
                onClick={searchBtnClick}
                data-qa="go-to-search-list"
                data-walkme="go-to-search-list"
            >
                Back to results
            </button>
        </div>
    );
};

export default LoanBackBtn;
