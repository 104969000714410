import React from "react";
import "./SearchResultsTable.scss";

function SearchResultsTable() {
    return (
        <div
            className="search-results-table"
            data-qa="search-results-table"
            data-walkme="search-results-table"
        >
            <div className="ds-wrapper ds-wrapper--flat">
                <table className="ds-table ds-table--indented ds-table--skeleton">
                    <caption>
                        <span className="screen-reader-only">Placeholder </span>
                        Loan search results
                    </caption>
                    <thead>
                        <tr>
                            <th className="column--loan-number">Loan number</th>

                            <th className="column--lastname">
                                Borrower last name
                            </th>
                            <th className="column--firstname">
                                Borrower first name
                            </th>
                            <th className="column--address">
                                Property address
                            </th>
                            <th className="column--city">City</th>
                            <th className="column--state">State</th>
                            <th className="column--zip">Zip code</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="skeleton-row">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="no-skeleton"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SearchResultsTable;
