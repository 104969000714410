export const documentTypes = [
    {
        DocumentTypeId: 17924,
        Name: "Appraisal",
        DocumentTypeKey: "Appraisal",
        ParentDocumentTypeId: null,
        CategoryId: 369,
        IsActive: true,
        IsTrained: true,
        BarCodeId: null
    },
    {
        DocumentTypeId: 18868,
        Name: "Appraisal Desk Review",
        DocumentTypeKey: "APPRAISALDESKREVIEW",
        ParentDocumentTypeId: null,
        CategoryId: 369,
        IsActive: true,
        IsTrained: null,
        BarCodeId: null
    },
    {
        DocumentTypeId: 17907,
        Name: "Appraisal Errors Compliance Certificate",
        DocumentTypeKey: "APPRAISALERRORSCOMPLIANCECERTIFICATE",
        ParentDocumentTypeId: null,
        CategoryId: 369,
        IsActive: true,
        IsTrained: null,
        BarCodeId: 1430
    },
    {
        DocumentTypeId: 18842,
        Name: "Appraisal Field Review",
        DocumentTypeKey: "APPRAISALFIELDREVIEW",
        ParentDocumentTypeId: null,
        CategoryId: 369,
        IsActive: true,
        IsTrained: null,
        BarCodeId: null
    },
    {
        DocumentTypeId: 17933,
        Name: "Appraisal Final Inspection",
        DocumentTypeKey: "APPRAISALFINALINSPECTION",
        ParentDocumentTypeId: null,
        CategoryId: 369,
        IsActive: true,
        IsTrained: null,
        BarCodeId: null
    },
    {
        DocumentTypeId: 19936,
        Name: "Appraisal Invoice",
        DocumentTypeKey: "APPRAISALINVOICE",
        ParentDocumentTypeId: null,
        CategoryId: 369,
        IsActive: true,
        IsTrained: null,
        BarCodeId: null
    },
    {
        DocumentTypeId: 18067,
        Name: "Appraisal Receipt Confirmation",
        DocumentTypeKey: "APPRAISALRECEIPTCONFIRMATION",
        ParentDocumentTypeId: null,
        CategoryId: 365,
        IsActive: true,
        IsTrained: null,
        BarCodeId: null
    },
    {
        DocumentTypeId: 18628,
        Name: "Conditions Needed to go to Closing",
        DocumentTypeKey: "CONDITIONSNEEDEDTOGOTOCLOSING",
        ParentDocumentTypeId: null,
        CategoryId: 363,
        IsActive: true,
        IsTrained: null,
        BarCodeId: null
    },
    {
        DocumentTypeId: 17903,
        Name: "Fannie Mae UCDP Submission Summary Report",
        DocumentTypeKey: "FANNIEMAEUCDPSUBMISSIONSUMMARYREPORT",
        ParentDocumentTypeId: null,
        CategoryId: 369,
        IsActive: true,
        IsTrained: true,
        BarCodeId: null
    }
];
