import React from "react";
import PropTypes from "prop-types";
import MainHeading from "../../../MainHeading/MainHeading";

import "./LoanInformation.scss";

function LoanInformation({
    LoanNumber,
    BorrowerFirstName,
    BorrowerLastName,
    PropertyStreetName,
    PropertyCity,
    PropertyState,
    PropertyZip
}) {
    return (
        <MainHeading>
            {LoanNumber && (
                <>
                    {LoanNumber} | {BorrowerLastName}, {BorrowerFirstName} |{" "}
                    {PropertyStreetName} | {PropertyCity} | {PropertyState} |{" "}
                    {PropertyZip}{" "}
                </>
            )}
        </MainHeading>
    );
}

LoanInformation.propTypes = {
    LoanNumber: PropTypes.string,
    BorrowerFirstName: PropTypes.string,
    BorrowerLastName: PropTypes.string,
    PropertyStreetName: PropTypes.string,
    PropertyCity: PropTypes.string,
    PropertyState: PropTypes.string,
    PropertyZip: PropTypes.string
};

export default LoanInformation;
