export const SEARCH_LOAN = "SEARCH_LOAN";
export const SEARCH_LOAN_SUCCESS = "SEARCH_LOAN_SUCCESS";
export const SEARCH_LOAN_ERROR = "SEARCH_LOAN_ERROR";

export const RESET_LOAN_SEARCH = "RESET_LOAN_SEARCH";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

//Current Loan
export const SET_CURRENT_LOAN = "SET_CURRENT_LOAN";
export const GET_CURRENT_LOAN = "GET_CURRENT_LOAN";
export const SET_CURRENT_LOAN_ERROR = "SET_CURRENT_LOAN_ERROR";
export const CLEAR_CURRENT_LOAN = "CLEAR_CURRENT_LOAN";

export const LOAD_DOCUMENTS = "LOAD_DOCUMENTS";
export const LOAD_DOCUMENTS_ERROR = "LOAD_DOCUMENTS_ERROR";
export const LOAD_DOCUMENTS_SUCCESS = "LOAD_DOCUMENTS_SUCCESS";

export const SELECTED_DOCUMENT = "SELECTED_DOCUMENT";

export const LOAD_FILE = "LOAD_FILE";
export const LOAD_FILE_ERROR = "LOAD_FILE_ERROR";
export const LOAD_FILE_SUCCESS = "LOAD_FILE_SUCCESS";

//Upload Documents
export const UPLOAD_START = "UPLOAD_START";
export const UPLOAD_PROGRESS_CHANGE = "UPLOAD_PROGRESS_CHANGE";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const UPLOAD_CLEAR = "UPLOAD_CLEAR";
