import apiClient from "./apiClient";

const { DMS_BASE_API } = window.runConfig;

/**
 *
 * @param {string} query BorrowerLastName, BorrowerFirstName, or LoanNumber
 */
export async function getLoans(query) {
    const endpointUrl = `${DMS_BASE_API}/api/v1/Folders/search?pageSize=100&searchValue=${query}`;
    const results = await apiClient().get(endpointUrl);

    return {
        query,
        loans: results.data.Items
    };
}
