import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { documentTypes } from "./documentTypes";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import "./UploadForm.scss";

export function UploadForm({
    filename,
    documentType,
    onCancel,
    onSubmit,
    uploadPercentComplete
}) {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState("");

    useEffect(() => {
        if (documentType && documentType.DocumentTypeId) {
            setSelectedDocumentTypeId(documentType.DocumentTypeId);
            setSubmitButtonDisabled(false);
        }
    }, [documentType]);

    const handleFormSubmit = event => {
        event.preventDefault();
        const selectedDocumentType = documentTypes.find(
            type => type.DocumentTypeId === parseInt(selectedDocumentTypeId, 10)
        );
        setFormSubmitted(true);
        onSubmit(selectedDocumentType);
    };

    const handleFormCancel = event => {
        event.preventDefault();
        onCancel();
    };

    const onDocumentTypeChange = event => {
        setSelectedDocumentTypeId(event.target.value);
        event.target.value !== ""
            ? setSubmitButtonDisabled(false)
            : setSubmitButtonDisabled(true);
    };

    return (
        <>
            <form
                name="uploadForm"
                data-qa="upload-form"
                data-walkme="upload-form"
                onSubmit={handleFormSubmit}
                className={
                    formSubmitted
                        ? "upload-form upload-form--disabled"
                        : "upload-form"
                }
            >
                <div
                    className="upload-form__file"
                    data-qa="upload-form-file"
                    data-walkme="upload-form-file"
                >
                    <div className="upload-form__document">
                        <div className="document__label">Document:</div>
                        <div className="document__filename">{filename}</div>
                    </div>
                    <div className="upload-form__type">
                        <label
                            className="ds-label ds-label--size__medium"
                            htmlFor="documentType"
                        >
                            Document Type:
                        </label>
                        <select
                            className="ds-select ds-select--size__medium"
                            name="documentType"
                            value={selectedDocumentTypeId}
                            onChange={onDocumentTypeChange}
                            disabled={formSubmitted}
                        >
                            <option value="">Select Document Type</option>
                            {documentTypes.map(type => (
                                <option
                                    value={type.DocumentTypeId}
                                    key={type.DocumentTypeId}
                                >
                                    {type.Name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="upload-form__controls">
                    <button
                        className="ds-button ds-button--cat__cancel ds-button--background__borderless ds-button--size__small"
                        onClick={handleFormCancel}
                        disabled={formSubmitted}
                    >
                        Cancel
                    </button>
                    <button
                        className={`ds-button ds-button--cat__primary ds-button--size__small ${
                            submitButtonDisabled
                                ? "ds-button--cat__disabled"
                                : null
                        }`}
                        disabled={submitButtonDisabled || formSubmitted}
                        type="submit"
                    >
                        Upload Documents {submitButtonDisabled}
                    </button>
                </div>
            </form>
            {formSubmitted && (
                <div className="uploading-status-indicator">
                    <LoadingIndicator />
                    <span>Uploading document {uploadPercentComplete}%</span>
                </div>
            )}
        </>
    );
}

UploadForm.propTypes = {
    /** The name of the file being uploaded */
    filename: PropTypes.string,

    /** Calback to cancel upload */
    onCancel: PropTypes.func,

    /** Callback for submitting upload */
    onSubmit: PropTypes.func,

    /** From State, Upload process (including all the API calls) percent complete.*/
    uploadPercentComplete: PropTypes.number
};

function mapStateToProps(state) {
    return {
        uploadPercentComplete: state.upload.percentComplete
    };
}

export default connect(mapStateToProps)(UploadForm);
