import apiClient, { pdfHeader } from "./apiClient";

const { DMS_BASE_API } = window.runConfig;

export async function getLoanDocuments(folderId) {
    const endpointUrl = `${DMS_BASE_API}/api/v1/Folders/${folderId}/documents`;

    return await apiClient().get(endpointUrl);
}

export const getFileForDocumentId = async documentId => {
    const endpointUrl = `${DMS_BASE_API}/api/v1/Documents/file/${documentId}`;
    const results = await pdfHeader().get(endpointUrl);

    return {
        data: results.data
    };
};

export async function getLoanByLoanFolderId(folderId) {
    const endpointUrl = `${DMS_BASE_API}/api/v1/Folders/${folderId}`;
    const results = await apiClient().get(endpointUrl);

    return results.data;
}

export async function downloadService(filename, pdfDocument) {
    const view = pdfDocument.data;
    const url = window.URL.createObjectURL(
        new Blob([view], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
}
