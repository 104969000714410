import React from "react";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";

export function LoanLoading() {
    return (
        <div
            className="loan-loading"
            data-qa="loan-loading"
            data-walkme="data-loading"
        >
            <LoadingIndicator loadingMessage="Loading" />
            <span>Loading Loan....</span>
        </div>
    );
}

export default LoanLoading;
