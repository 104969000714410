import React, { useState, useEffect } from "react";
import "./AmcFooterStyle.scss";
import { useLocation } from "react-router-dom";

function AmcFooter() {
    const [isLoanView, setIsLoanView] = useState(false);
    const copyrightYear = new Date().getFullYear();
    const location = useLocation();

    useEffect(() => {
        setIsLoanView(locationLookup(location.pathname));
    }, [location.pathname]);

    function locationLookup(pathname) {
        const regex = new RegExp("^(/loan)", "i");
        const path = pathname.substring(0, 5);
        return regex.test(path);
    }

    return (
        <>
            {!isLoanView ? (
                <footer
                    className="amc-footer"
                    data-qa="amc-footer"
                    data-walkme="amc-footer"
                >
                    <div
                        className="amc-footer__copyright"
                        data-qa="amc-footer--copyright"
                        data-walkme="amc-footer--copyright"
                    >
                        &copy; {copyrightYear} United Wholesale Mortgage
                    </div>
                </footer>
            ) : null}
        </>
    );
}

export default AmcFooter;
