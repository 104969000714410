import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ZoomOutBtn = ({ zoomOutBtnClick }) => {
    return (
        <button
            id="amc-viewer--zoom-out"
            className="ds-control-button ds-control-button--cat__secondary ds-control--size__small"
            data-qa="zoom-out"
            data-walme="zoom-out"
            aria-label="zoom-out"
            onClick={zoomOutBtnClick}
        >
            <FontAwesomeIcon icon={["fas", "minus"]} />
        </button>
    );
};

export default ZoomOutBtn;
