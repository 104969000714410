import React from "react";
import ReactDOM from "react-dom";
import DocHubAmcApp from "./AmcApp";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";
import { OidcProvider, loadUser } from "redux-oidc";
import userManager from "./utils/userManager";

export const store = configureStore();

loadUser(store, userManager);

ReactDOM.render(
    <ReduxProvider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <DocHubAmcApp />
        </OidcProvider>
    </ReduxProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
