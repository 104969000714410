import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uploadActions from "../../redux/actions/uploadActions";
import Modal from "./Modal/Modal";
import UploadForm from "./UploadForm/UploadForm";
import UploadSuccessMessage from "./UploadSuccessMessage/UploadSuccessMessage";
import UploadErrorMessage from "./UploadErrorMessage/UploadErrorMessage";
import "./UploadDocument.scss";

export function UploadDocument({
    onComplete,
    loanFolderId,
    uploadStatus,
    uploadFilename,
    uploadFileDocumentType,
    uploadError,
    actions
}) {
    const fileUploadElement = useRef(null);
    const uploadButtonElement = useRef(null);

    const maximumUploadSize = 100; // MB
    const intialUploadModalConfig = {
        name: "",
        type: "primary",
        title: "",
        filename: "",
        closeOnOverlayClick: true,
        closeOnEscapeKey: true
    };

    const [openModal, setOpenModal] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        ...intialUploadModalConfig
    });

    useEffect(() => {
        listenForUploadButtonClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**  Watch for Upload Status change coming from redux store state */
    useEffect(() => {
        handleStatusChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadStatus]);

    const handleStatusChange = () => {
        switch (uploadStatus) {
            case "IN_PROGRESS": {
                window.addEventListener(
                    "beforeunload",
                    handleBeforeUnload,
                    true
                );
                break;
            }
            case "SUCCESS": {
                window.removeEventListener(
                    "beforeunload",
                    handleBeforeUnload,
                    true
                );
                setModalConfig({
                    name: uploadStatus,
                    type: "success",
                    title: "Document uploaded successfully",
                    filename: uploadFilename,
                    message: "",
                    closeOnOverlayClick: true,
                    closeOnEscapeKey: true
                });
                setOpenModal(true);
                // tell parent upload completed successfully
                onComplete("SUCCESS");
                break;
            }
            case "ERROR": {
                window.removeEventListener(
                    "beforeunload",
                    handleBeforeUnload,
                    true
                );
                setModalConfig({
                    name: uploadStatus,
                    type: "error",
                    title: "Failed to upload document",
                    filename: "",
                    message: `Failed to upload ${uploadFilename}. ${uploadError}`,
                    closeOnOverlayClick: true,
                    closeOnEscapeKey: true
                });
                setOpenModal(true);
                break;
            }
            default:
                break;
        }
    };

    /** Adds event native event listenr to the Upload Button. This is necessary 
        because some browsers (ie. Firefox) will only accept a click on
        a file input if it comes from the original click-event-subscriber.*/
    const listenForUploadButtonClick = () => {
        uploadButtonElement.current.addEventListener(
            "click",
            function(e) {
                if (fileUploadElement.current) {
                    fileUploadElement.current.click();
                }
            },
            false
        );

        setOpenModal(false);
    };

    const onFileInputChange = event => {
        if (fileUploadElement.current.files[0].length === 0) {
            // No files were selected
            closeUploadModal();
        } else {
            /** NOTE: Even though file input is set to only open PDF docs,
             * the savvy user can overide it.  So we need to check the file type. */
            const validFileType =
                fileUploadElement.current.files[0].name.split(".").pop() ===
                "pdf";

            const validFileSize =
                fileUploadElement.current.files[0].size <
                maximumUploadSize * 1000000;

            if (validFileType && validFileSize) {
                setModalConfig({
                    name: "UPLOAD",
                    type: "primary",
                    title: "Upload Document",
                    filename: fileUploadElement.current.files[0].name,
                    message: "",
                    closeOnOverlayClick: false,
                    closeOnEscapeKey: false
                });
                setOpenModal(true);
            } else {
                setModalConfig({
                    name: "ERROR",
                    type: "error",
                    title: "Failed to upload document",
                    filename: "",
                    message: !validFileType
                        ? `Unable to upload ${fileUploadElement.current.files[0].name}. Only PDF files may be uploaded.`
                        : `Unable to upload ${fileUploadElement.current.files[0].name}. Files must be under ${maximumUploadSize} Mb to be uploaded.`,
                    closeOnOverlayClick: true,
                    closeOnEscapeKey: true
                });
                setOpenModal(true);
            }
        }
    };

    const onSubmitUpload = async documentType => {
        actions.uploadFile(
            loanFolderId,
            fileUploadElement.current.files[0],
            documentType
        );
    };

    const resetModal = () => {
        setModalConfig({
            ...intialUploadModalConfig
        });
        setOpenModal(false);
        actions.uploadClear();
    };

    const closeUploadModal = () => {
        setOpenModal(false);
        fileUploadElement.current.value = "";
        resetModal();
        uploadButtonElement.current.focus();
    };

    const retryUpload = () => {
        setModalConfig({
            name: "UPLOAD",
            type: "primary",
            title: "Upload Document",
            filename: fileUploadElement.current.files[0].name,
            documentType: uploadFileDocumentType,
            message: "",
            closeOnOverlayClick: false,
            closeOnEscapeKey: false
        });
        setOpenModal(true);

        //  resetModal();
    };

    return (
        <div className="upload-document">
            <input
                type="file"
                accept=".pdf"
                id="fileUpload"
                data-qa="upload-document-file-input"
                data-walkme="upload-document-file-input"
                className="upload-document__file-input"
                onChange={onFileInputChange}
                ref={fileUploadElement}
            />
            <button
                data-qa="amc-upload--btn"
                data-walkme="amc-upload--btn"
                className="upload-document__button ds-button ds-button--cat__secondary ds-button--size__small"
                ref={uploadButtonElement}
            >
                Upload document
            </button>
            {openModal && (
                <Modal
                    open={openModal}
                    onClose={closeUploadModal}
                    title={modalConfig.title}
                    type={modalConfig.type}
                    closeOnOverlayClick={modalConfig.closeOnOverlayClick}
                    closeOnEscapeKey={modalConfig.closeOnEscapeKey}
                >
                    {
                        {
                            UPLOAD: (
                                <UploadForm
                                    filename={modalConfig.filename}
                                    documentType={modalConfig.documentType}
                                    onSubmit={onSubmitUpload}
                                    onCancel={closeUploadModal}
                                />
                            ),
                            SUCCESS: (
                                <UploadSuccessMessage
                                    filename={modalConfig.filename}
                                    onClose={closeUploadModal}
                                />
                            ),
                            ERROR: (
                                <UploadErrorMessage
                                    errorMessage={modalConfig.message}
                                    onCancel={closeUploadModal}
                                    onTryAgain={retryUpload}
                                />
                            )
                        }[modalConfig.name]
                    }
                </Modal>
            )}
        </div>
    );
}

UploadDocument.propTypes = {
    /**
     * Function called when Upload Document Completed (Successfull or Unsuccessfully)
     */
    onComplete: PropTypes.func,
    /**
     * Function called when Upload Document is closed
     */
    onClose: PropTypes.func,
    /**
     * Id of the loan folder the document is to be uploaded to
     */
    loanFolderId: PropTypes.string,
    /**
     * From State, Upload Status from state. Values are -- null, IN_PROGRESS, SUCCESS, ERROR
     */
    uploadStatus: PropTypes.string,
    /**
     * From State, The name of the file being uploaded
     */
    uploadFilename: PropTypes.string,
    /**
     * From State, The type that the user selected for this file
     */
    uploadFileDocumentType: PropTypes.object,
    /**
     * From State, the error message recieved from what ever API call failed
     */
    uploadError: PropTypes.string,
    /**
     * Redux actions dispatched in this component
     */
    actions: PropTypes.object
};

export const handleBeforeUnload = event => {
    event.preventDefault();
    /** Chrome requires a return value but doesn't 
        actually use the message, some browsers do. */
    event.returnValue = "If you leave this page your upload will not complete.";
};

const mapStateToProps = state => {
    return {
        uploadStatus: state.upload.status,
        uploadFilename: state.upload.filename,
        uploadFileDocumentType: state.upload.documentType,
        uploadError: state.upload.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            uploadClear: bindActionCreators(
                uploadActions.uploadClear,
                dispatch
            ),
            uploadFile: bindActionCreators(uploadActions.uploadFile, dispatch),
            clearUploadFile: bindActionCreators(
                uploadActions.uploadFile,
                dispatch
            )
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);
