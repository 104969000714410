import * as types from "../actions/actionTypes";
import initialState from "../intialState";

export default function currentLoanReducer(
    state = initialState.currentLoan,
    action
) {
    switch (action.type) {
        case types.SET_CURRENT_LOAN_ERROR:
            return {
                ...state,
                ...initialState.currentLoan,
                loadingLoanError: action.error
            };
        case types.CLEAR_CURRENT_LOAN:
            return {
                ...state,
                ...initialState.currentLoan
            };
        case types.SET_CURRENT_LOAN:
            return {
                ...state,
                loan: action.loan,
                loadingLoan: false
            };
        case types.GET_CURRENT_LOAN:
            return {
                ...state,
                ...initialState.currentLoan,
                loadingLoan: true
            };
        case types.LOAD_FILE:
            return {
                ...state,
                loadingFile: true,
                loadingFileError: ""
            };
        case types.LOAD_FILE_SUCCESS:
            return {
                ...state,
                loadingFile: false,
                loadingFileError: ""
            };
        case types.LOAD_FILE_ERROR:
            return {
                ...state,
                loadingFile: false,
                loadingFileError: action.error
            };
        case types.LOAD_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: action.documents,
                loadingDocuments: false
            };
        case types.LOAD_DOCUMENTS:
            return {
                ...state,
                loadingDocuments: true
            };
        case types.LOAD_DOCUMENTS_ERROR:
            return {
                ...state,
                documents: [],
                selectedDocument: null,
                loadingDocuments: false,
                loadingDocumentsError: action.error
            };
        case types.SELECTED_DOCUMENT:
            return {
                ...state,
                selectedDocument: action.document
            };
        default:
            return state;
    }
}
