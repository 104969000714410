import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import MainHeading from "../../MainHeading/MainHeading";
import SearchInput from "./SearchInput/SearchInput";
import SearchResults from "./SearchResults/SearchResults";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as searchActions from "../../../redux/actions/searchActions";
import { store } from "../../../index.js";

import "./SearchLoan.scss";

export function SearchLoan({ loans, error, query, onOpen, actions }) {
    const [loadingResults, setLoadingResults] = useState(false);
    let inputQuery = query;
    let history = useHistory();

    useEffect(() => {
        setLoadingResults(false);
    }, [loans]);

    /** Handles Search Input Submit */
    function handleSearchSubmit(event) {
        event.preventDefault();
        const query = event.target.searchInput.value;
        if (query !== "") {
            event.preventDefault();
            setLoadingResults(true);
            actions.searchLoans(query);
        }
    }

    function handleSearchInput(event) {
        if (
            event.target.value === "" &&
            store.getState().search.loans.length > 0
        ) {
            actions.resetLoans();
        }
    }

    /** Handles when an onOpen event comes up from the SearchResults component */
    function handleOnOpen(folderId) {
        history.push(`/loan/${folderId}`);
    }

    return (
        <main className="amc-app--searchLoan amc-main">
            <MainHeading>Search for a loan</MainHeading>
            <SearchInput
                onSubmit={handleSearchSubmit}
                onChange={handleSearchInput}
                query={inputQuery}
            />
            <SearchResults
                loading={loadingResults}
                results={loans}
                query={query}
                errorMessage={error}
                onOpen={handleOnOpen}
            />
        </main>
    );
}

SearchLoan.propTypes = {
    loans: PropTypes.array,
    error: PropTypes.string,
    query: PropTypes.string,
    onOpen: PropTypes.func,
    actions: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        loans: state.search.loans,
        query: state.search.query,
        error: state.search.error
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            searchLoans: bindActionCreators(searchActions.loadLoans, dispatch),
            resetLoans: bindActionCreators(
                searchActions.resetLoanSearch,
                dispatch
            )
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchLoan);
