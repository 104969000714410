import React from "react";
import PropTypes from "prop-types";
import "./UploadErrorMessage.scss";

export function UploadErrorMessage({ errorMessage, onTryAgain, onCancel }) {
    const handleOnTryAgain = event => {
        event.preventDefault();
        onTryAgain();
    };

    const handleFormCancel = event => {
        event.preventDefault();
        onCancel();
    };

    return (
        <div className="upload-error-message">
            <div className="upload-error__text">{errorMessage}</div>
            <div className="upload-error__controls">
                <button
                    className="ds-button ds-button--cat__cancel ds-button--background__borderless ds-button--size__small"
                    onClick={handleFormCancel}
                >
                    Cancel
                </button>
                <button
                    className="ds-button ds-button--cat__primary ds-button--size__small"
                    onClick={handleOnTryAgain}
                >
                    Try Again
                </button>
            </div>
        </div>
    );
}

UploadErrorMessage.propTypes = {
    /** message to display */
    errorMessage: PropTypes.string,

    /** Callback to cancel upload */
    onCancel: PropTypes.func,

    /** Callback to retry upload */
    onTryAgain: PropTypes.func
};

export default UploadErrorMessage;
