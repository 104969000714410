import React from "react";
import PropTypes from "prop-types";
import "./LoadingIndicator.scss";

/** Displays 3 dot indeterminant loading indicator */
const LoadingIndicator = ({ loadingMessage, styles }) => {
    return (
        <div
            className="amc-loading-indicator"
            role="alert"
            aria-live="assertive"
            data-qa="loading-indicator"
            data-walkme="loading-indicatior"
            title={loadingMessage}
            style={styles}
        >
            <span className="amc-loading-indicator__node"></span>
            <span className="amc-loading-indicator__node"></span>
            <span className="amc-loading-indicator__node"></span>
            <span className="amc-loading-indicator__label">
                {loadingMessage}
            </span>
        </div>
    );
};

LoadingIndicator.propTypes = {
    /** Text message for Loading Indicator.  Typically not visible but used for Acccessibility. */
    loadingMessage: PropTypes.string,
    /** Additional inline styles to be added to the loading indicator */
    styles: PropTypes.object
};

LoadingIndicator.defaultProps = {
    loadingMessage: "Loading...",
    styles: {}
};

export default LoadingIndicator;
