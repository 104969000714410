import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RotateButton = ({ onClick, icon, direction }) => {
    return (
        <button
            id={`amc-viewer--${direction}`}
            className="ds-control-button ds-control-button--cat__secondary"
            data-qa={`rotate-${direction}`}
            data-walkme={`rotate-${direction}`}
            aria-label={`rotate-${direction}`}
            onClick={onClick.bind(null, { direction })}
        >
            <FontAwesomeIcon icon={["fas", `${icon}`]} />
        </button>
    );
};

RotateButton.defaultProps = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    direction: PropTypes.string
};

export default RotateButton;
