import React from "react";
import "./DocumentDownloadBtn.scss";

const DocumentDownloadBtn = ({ downloadBtnClick }) => {
    return (
        <button
            id="amc-viewer--download"
            className="ds-button ds-button--cat__secondary ds-button--size__small"
            onClick={downloadBtnClick}
            data-qa="amc-download-btn"
            data-walkme="amc-download-btn"
        >
            Download document
        </button>
    );
};

export default DocumentDownloadBtn;
