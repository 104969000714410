import React from "react";
import PropTypes from "prop-types";
import AmcUser from "./AmcUser/AmcUser";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import { connect } from "react-redux";
import DochubLogo from "../../assets/dochub.svg";
import "./AmcHeader.scss";

export function AmcHeader({ username, onLogin, onLogout, authentication }) {
    return (
        <header className="amc-header">
            <div
                className="amc-header--brand"
                data-qa="amc-title"
                data-walkme="amc-title"
            >
                <div
                    className="amc-logo"
                    data-qa="amc-logo"
                    data-walkme="amc-logo"
                >
                    <img src={DochubLogo} alt="Dochub Logo" />
                </div>
            </div>
            <div
                className="amc-header--user"
                data-qa="amc-user"
                data-walkme="amc-user"
            >
                {!authentication.isLoggedIn ? (
                    <button
                        className="amc-header--login ds-button ds-button--cat__secondary ds-button--size__small ds-button--background__ghost"
                        onClick={onLogin}
                        data-qa="amc-login"
                        data-walkme="amc-login"
                    >
                        Login
                    </button>
                ) : null}
                {authentication.isLoggedIn ? (
                    <AmcUser username={username} logout={onLogout} />
                ) : null}
            </div>
        </header>
    );
}

AmcHeader.propTypes = {
    username: PropTypes.string,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func,
    authentication: PropTypes.object
};

function mapStateToProps(state) {
    return { authentication: state.authentication };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            logoutUser: bindActionCreators(userActions.logoutUser, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AmcHeader);
