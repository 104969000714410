import React, { useState } from "react";
import "./SearchResultsTable.scss";

function SearchResultsTable({ results, onOpen }) {
    const [focusedRow, setFocusedRow] = useState(null);

    /**
     * When a button takes focus it should highlight the row/
     */
    function handleOpenButtonOnFocus(event) {
        const resultId = event.currentTarget.getAttribute("button-id");
        setFocusedRow(parseInt(resultId, 10));
    }

    /**
     * Set focus on the coresponding open button when a table cell is clicked.
     */
    function setOpenButtonFocus(event) {
        const resultId = event.currentTarget.parentNode.getAttribute(
            "data-result-id"
        );
        const selector = `[button-id='${resultId}']`;
        document.querySelector(selector).focus();
    }

    return (
        <div
            className="search-results-table"
            data-qa="search-results-table"
            data-walkme="search-results-table"
        >
            <div className="ds-wrapper">
                <table className="ds-table ds-table--indented">
                    <caption>Loan search results</caption>
                    <thead>
                        <tr>
                            <th>Loan number</th>

                            <th className="column--lastname">
                                Borrower last name
                            </th>
                            <th className="column--firstname">
                                Borrower first name
                            </th>
                            <th className="column--address">
                                Property address
                            </th>
                            <th className="column--city">City</th>
                            <th>State</th>
                            <th>Zip code</th>
                            <th className="column--open"> </th>
                        </tr>
                    </thead>
                    <tbody
                        data-qa="search-results--table-body"
                        data-walkme="search-results--table-body"
                    >
                        {results.map((result, index) => {
                            return (
                                <tr
                                    key={result.FolderId}
                                    data-result-id={result.FolderId}
                                    onDoubleClick={onOpen.bind(
                                        null,
                                        result.FolderId
                                    )}
                                    className={
                                        focusedRow === result.FolderId
                                            ? "focused"
                                            : "notfocused"
                                    }
                                    data-qa={`search-results-row-${result.FolderId}`}
                                    data-walkme={`search-results-row-${result.FolderId}`}
                                >
                                    <td onClick={setOpenButtonFocus}>
                                        {result.LoanNumber}
                                    </td>
                                    <td
                                        className="column--lastname"
                                        onClick={setOpenButtonFocus}
                                    >
                                        {result.BorrowerLastName}
                                    </td>
                                    <td
                                        className="column--firstname"
                                        onClick={setOpenButtonFocus}
                                    >
                                        {result.BorrowerFirstName}
                                    </td>
                                    <td
                                        className="column--address"
                                        onClick={setOpenButtonFocus}
                                    >
                                        {result.PropertyStreetName}
                                    </td>
                                    <td
                                        className="column--city"
                                        onClick={setOpenButtonFocus}
                                    >
                                        {result.PropertyCity}
                                    </td>
                                    <td onClick={setOpenButtonFocus}>
                                        {result.PropertyState}
                                    </td>
                                    <td onClick={setOpenButtonFocus}>
                                        {result.PropertyZip}
                                    </td>
                                    <td
                                        className="column--open"
                                        onClick={setOpenButtonFocus}
                                        data-qa="search-results-table--open"
                                        data-walkme="search-results-table--open"
                                    >
                                        <button
                                            className="ds-button ds-button--cat__secondary ds-button--size__small ds-button--background__ghost"
                                            onFocus={handleOpenButtonOnFocus}
                                            onClick={onOpen.bind(
                                                null,
                                                result.FolderId
                                            )}
                                            aria-label={
                                                "Open Loan Folder " +
                                                result.LoanNumber
                                            }
                                            button-id={result.FolderId}
                                            data-qa="search-results-row-open-button"
                                            data-walkme="search-results-row-open-button"
                                        >
                                            Open
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SearchResultsTable;
