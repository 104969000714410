import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MainHeading from "../../MainHeading/MainHeading";
import userManager from "../../../utils/userManager";

import * as userActions from "../../../redux/actions/userActions";

import "./callback.scss";

const CallbackPage = ({ history }) => {
    useEffect(() => {
        userManager
            .signinRedirectCallback()
            .then(function() {
                history.push("/search");
            })
            .catch(function(err) {
                console.error(err);
            });
    }, [history]);

    return (
        <main className="amc-main amc-app--landing">
            <MainHeading />
            <div>Redirecting...</div>
        </main>
    );
};

function mapStateToProps(state) {
    return { authentication: state.authentication };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loginUser: bindActionCreators(userActions.loginUser, dispatch),
            logoutUser: bindActionCreators(userActions.logoutUser, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
