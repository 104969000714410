import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf";

/** NOTE: See documentation pdf.worker.md for full explaination of this code.*/
import { pdfjs } from "react-pdf";

const { DMS_REDIRECT_URL } = window.runConfig;

pdfjs.GlobalWorkerOptions.workerSrc = `${DMS_REDIRECT_URL}/pdf.worker.min.js`;

export function DocumentSection({
    fileData,
    options,
    begin,
    end,
    onDocumentLoadSuccess,
    onDocumentLoadError,
    onPageLoadSuccess,
    onPageSelect,
    pageRotations,
    scale,
    selectedPage
}) {
    const [sectionLength, setSectionLength] = useState(end - begin);
    const rotations = [0, 90, 180, 270];
    const selectedDocument = [...pageRotations].splice(begin);

    useEffect(() => {
        resetState();
    }, [fileData]);

    useEffect(() => {
        setSectionLength(sectionLength);
    }, [sectionLength]);

    const resetState = () => {
        setSectionLength(0);
    };

    return (
        <>
            {fileData.data && selectedDocument.length && (
                <Document
                    file={fileData}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    options={options}
                >
                    {Array.from(
                        new Array(selectedDocument.length),
                        (el, index) => (
                            <Page
                                key={`page_${index + begin}`}
                                pageNumber={index + 1 + begin}
                                scale={scale}
                                rotate={
                                    rotations[
                                        pageRotations[index + begin].Rotation
                                    ]
                                }
                                className={
                                    selectedPage > -1 &&
                                    selectedPage === index + begin
                                        ? "highlight"
                                        : ""
                                }
                                onClick={onPageSelect.bind(null, {
                                    pageNum: index + begin
                                })}
                                onLoadSuccess={onPageLoadSuccess.bind(null, {
                                    pageNum: index + begin
                                })}
                            />
                        )
                    )}
                </Document>
            )}
        </>
    );
}

DocumentSection.propTypes = {
    /** object with "data" property in Uint8Array format */
    fileData: PropTypes.object,
    /** PDF Viewer options that will be passed to PDF.js */
    options: PropTypes.object,
    /** Begin rendering at this index */
    begin: PropTypes.number,
    /** Stop rendering at this index */
    end: PropTypes.number,
    onDocumentLoadSuccess: PropTypes.func,
    onDocumentLoadError: PropTypes.func,
    onPageLoadSuccess: PropTypes.func,
    onPageSelect: PropTypes.func,
    /** Array of rotations for each page of the document */
    pageRotations: PropTypes.array,
    /** Zoom scale for all the pages */
    scale: PropTypes.number,
    /* Index of the selected page */
    selectedPage: PropTypes.number
};

DocumentSection.defaultProps = {
    onDocumentLoadSuccess: () => {},
    onPageLoadSuccess: () => {}
};

export default DocumentSection;
