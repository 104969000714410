import React from "react";
import LoadingIndicator from "../../../LoadingIndicator/LoadingIndicator";

export function DocumentListLoading() {
    return (
        <div
            className="document-list__not-viewable document-list__not-viewable--loading"
            data-qa="document-list-loading"
            data-walkme="document-list-loading"
        >
            Loading loan documents...{" "}
            <LoadingIndicator loadingMessage="Loading" />
        </div>
    );
}

export default DocumentListLoading;
