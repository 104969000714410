import React from "react";
import "./MainHeading.scss";

export function MainHeading({ children }) {
    return (
        <h1
            className="amc-main-heading"
            data-qa="amc-page--title"
            data-walkme="amc-page--title"
        >
            {children}
        </h1>
    );
}
export default MainHeading;
