import * as types from "./actionTypes";
import * as userApi from "../../services/userService";
import userManager from "../../utils/userManager";

export function loginRequest() {
    return {
        type: types.LOGIN_REQUEST,
    };
}

export function loginSuccess(userObj) {
    return {
        type: types.LOGIN_SUCCESS,
        user: userObj,
    };
}

export function loginError(error) {
    return {
        type: types.LOGIN_FAIL,
        error,
    };
}

export function loginUser() {
    return function (dispatch) {
        // dispatch(loginRequest());
        return userApi
            .login()
            .then((results) => {
                localStorage.setItem(
                    "current_user",
                    JSON.stringify({
                        fullName: results.fullName,
                        userId: results.userId,
                        email: results.email,
                        isAuthorized: results.isAuthorized,
                    })
                );
                dispatch(loginSuccess(results));
            })
            .catch((error) => {
                dispatch(loginError(error));
                throw error;
            });
    };
}

export function logoutUser() {
    localStorage.removeItem("current_user");
    userManager.removeUser();

    return {
        type: types.LOGOUT,
    };
}
