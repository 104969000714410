import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentLoanActions from "../../../redux/actions/currentLoanActions";

import LoanInformation from "./LoanInformation/LoanInformation";
import LoanBackBtn from "./LoanBackBtn/LoanBackBtn";
import UploadDocument from "../../UploadDocument/UploadDocument";
import DocumentList from "./DocumentList/DocumentList";
import LoanLoading from "./LoanLoading";
import LoanError from "./LoanError";
import PdfViewer from "./PdfViewer/PdfViewer";

import "./Loan.scss";

export function Loan({
    match,
    selectedDocument,
    loadingDocumentsError,
    loan,
    loadingLoan,
    loadingLoanError,
    loadingFileError,
    actions
}) {
    let history = useHistory();
    const [selectedDocumentData, setSelectedDocumentData] = useState({});
    /** When the Loan view first loads, set the current loan on state using
     * the route's Loan Number or if there isn't one go to the search page. */
    useEffect(() => {
        if (match.params.LoanNumber) {
            actions.setCurrentLoanByLoanNumber(match.params.LoanNumber);
        } else {
            history.push(`/search`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Get the Selected Document URL for PDF Viewer */
    useEffect(() => {
        if (selectedDocument && selectedDocument.DocumentName !== "") {
            setSelectedDocumentData({});
            actions
                .getFileForSelectedDocument(selectedDocument.DocumentId)
                .then(data => {
                    setSelectedDocumentData(data);
                });
        }
    }, [selectedDocument, actions]);

    const handleSearchBtnClick = () => {
        history.push(`/search`);
        actions.clearCurrentLoan();
    };

    const handleUploadComplete = status => {
        if (status === "SUCCESS") {
            actions.loadDocumentsByLoanFolderId(loan.FolderId);
        }
    };

    const handleLoadLoanRetry = () => {
        actions.setCurrentLoanByLoanNumber(match.params.LoanNumber);
    };

    const handlePdfFileRetry = () => {
        actions
            .getFileForSelectedDocument(selectedDocument.DocumentId)
            .then(data => {
                setSelectedDocumentData(data);
            });
    };

    const renderContent = () => {
        if (loadingLoan) {
            return <LoanLoading />;
        }
        if (loadingLoanError) {
            return (
                <LoanError
                    error={loadingLoanError}
                    retry={handleLoadLoanRetry}
                    cancel={handleSearchBtnClick}
                />
            );
        }

        if (loan) {
            return (
                <>
                    <div className="loan-controls">
                        <LoanBackBtn searchBtnClick={handleSearchBtnClick} />
                    </div>
                    <div className="loan-body">
                        <div className="loan-body__left">
                            <div className="ds-wrapper">
                                <DocumentList />
                                {loadingDocumentsError === "" && (
                                    <UploadDocument
                                        onComplete={handleUploadComplete}
                                        loanFolderId={loan.FolderId.toString()}
                                    />
                                )}
                            </div>
                        </div>
                        {selectedDocument && loadingDocumentsError === "" && (
                            <div className="loan-body__right ds-wrapper">
                                <PdfViewer
                                    filename={selectedDocument.DocumentName}
                                    fileData={selectedDocumentData}
                                    fileLoadingError={loadingFileError}
                                    retryFileLoad={handlePdfFileRetry}
                                ></PdfViewer>
                            </div>
                        )}
                    </div>
                </>
            );
        }
        return <></>;
    };

    return (
        <main className="amc-main">
            <LoanInformation {...loan}></LoanInformation>
            {renderContent()}
        </main>
    );
}

Loan.propTypes = {
    match: PropTypes.object,
    selectedDocument: PropTypes.object,
    loan: PropTypes.object,
    actions: PropTypes.object
};

function mapStateToProps(state) {
    return {
        loan: state.currentLoan.loan,
        loadingLoan: state.currentLoan.loadingLoan,
        loadingLoanError: state.currentLoan.loadingLoanError,
        selectedDocument: state.currentLoan.selectedDocument,
        loadingDocumentsError: state.currentLoan.loadingDocumentsError,
        loadingFileError: state.currentLoan.loadingFileError
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setCurrentLoanByLoanNumber: bindActionCreators(
                currentLoanActions.setCurrentLoanByLoanNumber,
                dispatch
            ),
            loadDocumentsByLoanFolderId: bindActionCreators(
                currentLoanActions.loadDocumentsByLoanFolderId,
                dispatch
            ),
            clearCurrentLoan: bindActionCreators(
                currentLoanActions.clearCurrentLoan,
                dispatch
            ),
            getFileForSelectedDocument: bindActionCreators(
                currentLoanActions.getFileForSelectedDocument,
                dispatch
            )
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Loan);
