import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import "./DocumentListTable.scss";

export function DocumentListTable({
    currentLoanDocuments,
    selectedDocument,
    rowClick,
    rowKeyPress
}) {
    const formattedDate = uploadDate => {
        if (uploadDate === null) {
            return "";
        }

        const date = moment.utc(uploadDate);

        return `${date.local().format("MM/DD/YY hh:mm a")} ${moment
            .tz(moment.tz.guess())
            .format("z")}`;
    };

    const addSelectedClassName = (selectedDocument, document) => {
        return selectedDocument &&
            selectedDocument.DocumentId === document.DocumentId
            ? "selected"
            : null;
    };

    return (
        <>
            {currentLoanDocuments && currentLoanDocuments.length === 0 ? (
                <div
                    className="document-list__not-viewable"
                    data-qa="document-list-empty"
                    data-walkme="document-list-empty"
                >
                    There are no viewable documents.
                </div>
            ) : (
                <table
                    className=" dl-table ds-table ds-table--selectable ds-table--indented"
                    data-qa="document-list-table"
                    data-walkme="document-list-table"
                >
                    <thead
                        data-qa="document-list-table-head"
                        data-walkme="document-list-table-head"
                    >
                        <tr>
                            <th>Document name</th>
                            <th>Comments</th>
                            <th className="dl-table-header-col--date">
                                Upload date
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        data-qa="document-list-table-body"
                        data-walkme="document-list-table-body"
                    >
                        {currentLoanDocuments.map(document => (
                            <tr
                                key={document.DocumentId}
                                tabIndex="0"
                                onClick={rowClick.bind(this, document)}
                                onKeyPress={event =>
                                    rowKeyPress.call(null, event, document)
                                }
                                className={addSelectedClassName(
                                    selectedDocument,
                                    document
                                )}
                                data-qa={document.DocumentId}
                                data-walkme={document.DocumentId}
                            >
                                <td>{document.DocumentName}</td>
                                <td>{document.Comment}</td>
                                <td className="dl-table-col--date">
                                    {formattedDate(document.UploadedDateUTC)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
}

DocumentListTable.propTypes = {
    currentLoanDocuments: PropTypes.array.isRequired,
    selectedDocument: PropTypes.object,
    rowClick: PropTypes.func,
    rowKeypress: PropTypes.func
};

export default DocumentListTable;
