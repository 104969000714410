import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf";
import { downloadService } from "../../../../services/currentLoanService";
import PdfViewerControls from "./PdfViewerControls/PdfViewerControls";
import PdfViewerError from "./PdfViewerError/PdfViewerError";
import DocumentSection from "./DocumentSection/DocumentSection";
import LoadingIndicator from "../../../LoadingIndicator/LoadingIndicator";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentLoanActions from "../../../../redux/actions/currentLoanActions";

import "./PdfViewer.scss";

/** NOTE: See documentation pdf.worker.md for full explaination of this code.*/
import { pdfjs } from "react-pdf";

const { DMS_REDIRECT_URL } = window.runConfig;

pdfjs.GlobalWorkerOptions.workerSrc = `${DMS_REDIRECT_URL}/pdf.worker.min.js`;

export function PdfViewer({
    filename,
    fileData,
    fileLoadingError,
    retryFileLoad,
    selectedPages
}) {
    const [numPages, setNumPages] = useState(0);
    const [pdfError, setPdfError] = useState(false);
    const [scale, setScale] = useState(1.0);
    const [currentSelectedPages, setPageRotations] = useState(selectedPages);
    const [selectedPage, setSelectedPage] = useState(-1);
    const defaultInitialSectionLength = 4;
    const [initialSectionLength, setInitialSectionLength] = useState(
        defaultInitialSectionLength
    );
    const [additionalSectionLoad, setAdditionalSectionLoad] = useState(false);
    const pdfViewerDocumentElement = useRef(null);

    const options = {
        cMapUrl: "cmaps/",
        cMapPacked: true
    };

    const rotationValues = [0, 90, 180, 270];

    useEffect(() => {
        resetState();
    }, [fileData]);

    useEffect(() => {
        setNumPages(currentSelectedPages.length);
    }, [currentSelectedPages]);

    useEffect(() => {
        setSelectedPage(selectedPage);
    }, [selectedPage]);

    const onDocumentLoadSuccess = pdf => {
        setNumPages(pdf.numPages);
        if (pdf.numPages <= initialSectionLength) {
            setInitialSectionLength(pdf.numPages);
        }
        setPageRotations(selectedPages);
    };

    const onDocumentLoadError = event => {
        setPdfError(true);
    };

    const handlePageLoadSuccess = page => {
        if (page.pageNum === initialSectionLength - 1) {
            if (numPages > initialSectionLength) {
                setAdditionalSectionLoad(true);
            }
        }
    };

    const handleOnSelect = pageNum => {
        setSelectedPage(pageNum);

        selectedPage === pageNum.pageNum
            ? setSelectedPage(-1)
            : setSelectedPage(pageNum.pageNum);
    };

    const handleScaleChange = newScale => {
        setScale(newScale);
    };

    const handleRotationChange = direction => {
        let newPageRotations;
        if (selectedPage > -1) {
            newPageRotations = [...currentSelectedPages];
            let tempIndex = adjustRotationIndex(
                newPageRotations[selectedPage].Rotation + direction
            );

            newPageRotations[selectedPage].Rotation = adjustRotationIndex(
                tempIndex
            );
            setPageRotations(newPageRotations);
        } else {
            newPageRotations = currentSelectedPages.map(x => {
                x.Rotation = x.Rotation + direction;
                x.Rotation = adjustRotationIndex(x.Rotation);
                return x;
            });
            setPageRotations(newPageRotations);
        }
    };

    const adjustRotationIndex = index => {
        if (index < 0) {
            return rotationValues.length - 1;
        } else if (index >= rotationValues.length) {
            return 0;
        }
        return index;
    };

    const handleDownloadDocument = () => {
        downloadService(filename, fileData);
    };

    const resetState = () => {
        setNumPages(0);
        setInitialSectionLength(defaultInitialSectionLength);
        setPageRotations([]);
        setSelectedPage(-1);
        setPdfError(false);
        setAdditionalSectionLoad(false);
        pdfViewerDocumentElement.current.scrollTop = 0;
    };

    return (
        <section className="pdf-viewer" id="pdfViewer">
            <h1
                className="ds-section-heading"
                data-qa="amc-document--title"
                data-walkme="amc-document--title"
            >
                {filename}
            </h1>

            {!fileLoadingError && (
                <PdfViewerControls
                    scale={scale}
                    onScaleChange={handleScaleChange}
                    onRotationChange={handleRotationChange}
                    onDownloadDocument={handleDownloadDocument}
                />
            )}

            <div
                className="pdf-viewer--document"
                ref={pdfViewerDocumentElement}
                tabIndex="0"
            >
                {fileData.data && !pdfError && !fileLoadingError && (
                    <Document
                        file={fileData}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                        options={options}
                        loading={
                            <LoadingIndicator loadingMessage="Loading Document..." />
                        }
                    >
                        {currentSelectedPages.length > 0 &&
                            Array.from(
                                new Array(initialSectionLength),
                                (el, index) => (
                                    <Page
                                        key={`page_${currentSelectedPages[index].DocumentPageId}`}
                                        onClick={handleOnSelect.bind(null, {
                                            pageNum: index
                                        })}
                                        pageNumber={index + 1}
                                        scale={scale}
                                        rotate={
                                            rotationValues[
                                                currentSelectedPages[index]
                                                    .Rotation
                                            ]
                                        }
                                        className={
                                            selectedPage > -1 &&
                                            selectedPage === index
                                                ? "highlight"
                                                : ""
                                        }
                                        onLoadSuccess={handlePageLoadSuccess.bind(
                                            null,
                                            {
                                                pageNum: index
                                            }
                                        )}
                                    />
                                )
                            )}
                    </Document>
                )}

                {additionalSectionLoad && !pdfError && !fileLoadingError && (
                    <DocumentSection
                        fileData={fileData}
                        options={options}
                        begin={initialSectionLength}
                        end={numPages}
                        onDocumentLoadError={onDocumentLoadError}
                        onPageSelect={handleOnSelect}
                        pageRotations={currentSelectedPages}
                        scale={scale}
                        selectedPage={selectedPage}
                    />
                )}
                {pdfError && !fileLoadingError && <PdfViewerError />}
                {fileLoadingError && (
                    <PdfViewerError
                        error={fileLoadingError}
                        retry={retryFileLoad}
                    />
                )}
            </div>
        </section>
    );
}

function mapStateToProps(state) {
    return {
        selectedPages: state.currentLoan.selectedDocument.DocumentPages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            getFileForSelectedDocument: bindActionCreators(
                currentLoanActions.getFileForSelectedDocument,
                dispatch
            )
        }
    };
}

PdfViewer.propTypes = {
    filename: PropTypes.string,
    fileData: PropTypes.object,
    fileLoadingError: PropTypes.string,
    retryFileLoad: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
