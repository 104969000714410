import React, { useState } from "react";
import PropTypes from "prop-types";

import "./AmcUser.scss";

function AmcUser({ username, logout }) {
    const [menu, setShowMenu] = useState({ showMenu: false });

    function handleClick(menuStatus) {
        setShowMenu({ showMenu: !menuStatus.showMenu });
    }

    return (
        <div className="amc-user" onClick={() => handleClick(menu)}>
            <span>{username}</span>
            <button
                className="ds-button ds-button--cat__secondary ds-button--size__small ds-button--background__ghost"
                onClick={logout}
                data-qa="amc-logout"
                data-walkme="amc-logout"
            >
                Log out
            </button>
        </div>
    );
}

AmcUser.propTypes = {
    username: PropTypes.string,
    logout: PropTypes.func
};

export default AmcUser;
