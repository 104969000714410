export const errorMessage = {
    DEFAULT: "There was a problem.  Please wait a few moments and try again.",
    // bad request - server cannot or will not process
    STATUS400:
        "The server could not process your request. Please wait a few moments and try again.",
    // unauthorized
    STATUS401: "Please verify that you are logged in and try again.",
    // internal server error
    STATUS500:
        "The error appears to be on our end. Please wait a few moments and try again.",
    // service unavailable
    STATUS503:
        "Our server is busier than expected. Please wait a few moments and try again.",
    TIMEOUT:
        "Something took too long. Please wait a few moments and try again.",
    NETWORK_ERROR:
        "There was a network error.  Please wait a few moments and try again.",
    ACCESS_TOKEN: "Your login expired. Try logging in again.",
    FILE_ERROR:
        "There was a problem while uploading your file. Please wait a few moments and try again."
};

const createAPIErrorMessage = error => {
    let message = errorMessage.DEFAULT;

    if (error.response && error.response.status) {
        switch (error.response.status) {
            case 400:
                message = errorMessage.STATUS400;
                break;
            case 401:
                message = errorMessage.STATUS401;
                break;
            case 500:
                message = errorMessage.STATUS500;
                break;
            case 503:
                message = errorMessage.STATUS503;
                break;
            default:
                message = errorMessage.DEFAULT;
        }
    } else if (error.message) {
        if (error.message.includes("timeout")) {
            message = errorMessage.TIMEOUT;
        } else if (error.message.includes("Network Error")) {
            message = errorMessage.NETWORK_ERROR;
        } else if (error.message && error.message.includes("access_token")) {
            message = errorMessage.ACCESS_TOKEN;
        } else if (error.message === "File Error") {
            message = errorMessage.FILE_ERROR;
        }
    }
    return message;
};

export default createAPIErrorMessage;
