import React from "react";
import "./UnAuthorized.scss";

const unauthMessage = "You do not have authorization to access this page.";
const UnAuthorized = () => {
    return (
        <main
            className="amc-main amc-401--container"
            data-qa="amc-unauthorized"
            data-walkme="amc-unauthorized"
        >
            <h2>OOPS!</h2>
            <p className="amc-401-subtitle"> {unauthMessage} </p>
        </main>
    );
};

export default UnAuthorized;
