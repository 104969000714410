import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./Modal.scss";

export function Modal({
    children,
    type = "primary",
    title,
    open,
    onClose,
    displayClose = false,
    closeOnOverlayClick = true,
    closeOnEscapeKey = true
}) {
    /**  Determines if modal is shown or not. Usually matchs open prop value,
     * but can be set internaly to immediately hide modal. */
    const [showModal, setshowModal] = useState(false);

    useEffect(() => {
        setshowModal(open);
        if (open) {
            initializeModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const initializeModal = () => {
        //listen for (Esc) key
        document.addEventListener("keydown", handleKeydown);
    };

    /** Resets modal on close */
    const closeModal = () => {
        document.removeEventListener("keydown", handleKeydown);
        setshowModal(false);
        // call the onClose callback
        onClose();
    };

    const handleKeydown = event => {
        if (closeOnEscapeKey && event.keyCode === 27) {
            closeModal();
        }
    };

    /** Simply prevents a click inside of the modal from
     * propogating down to overlay */
    const handleModalClick = event => {
        event.stopPropagation();
    };

    const handleOverlayClick = () => {
        if (closeOnOverlayClick) {
            closeModal();
        }
    };

    const Modal_Content = (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div
                className={"modal modal--type-" + type}
                aria-modal="true"
                role="dialog"
                onClick={handleModalClick}
            >
                {title && <div className="modal--title">{title}</div>}
                {displayClose && (
                    <button className="modal--close" onClick={closeModal}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 36 36"
                        >
                            <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />
                            <span>Close Modal</span>
                        </svg>
                    </button>
                )}
                <div className="modal--body">{children}</div>
            </div>
        </div>
    );

    /** Render Modal in modal portal located in index.html */
    if (showModal) {
        return ReactDOM.createPortal(
            Modal_Content,
            document.querySelector("#modal")
        );
    } else {
        return null;
    }
}

Modal.propTypes = {
    /**
     * The content of the modal.
     */
    children: PropTypes.node,
    /**
     * The content of the modal.
     */
    title: PropTypes.string,
    /**
     * The type of modal.  Valid types are primary (default), success, warning, or error
     */
    type: PropTypes.string,
    /**
     * Used by parent to request modal to opened or not.
     */
    open: PropTypes.bool.isRequired,
    /**
     * Callback fired when the Modal is requested to be closed by a click on the overlay or when user press esc key.
     */
    onClose: PropTypes.func.isRequired,
    /**
     * Controls whether or not the close control appears in the top right corner of the modal.
     */
    displayClose: PropTypes.bool,
    /**
     * Close modal when overlay is clicked
     */
    closeOnOverlayClick: PropTypes.bool,
    /**
     * Close modal when Escape key clicked
     */
    closeOnEscapeKey: PropTypes.bool
};

export default Modal;
