import * as types from "../actions/actionTypes";
import initialState from "../intialState";

export default function searchReducer(state = initialState.search, action) {
    switch (action.type) {
        case types.SEARCH_LOAN:
            return state;
        case types.SEARCH_LOAN_SUCCESS:
            return {
                ...state,
                loans: action.loans,
                query: action.query,
                error: null
            };
        case types.RESET_LOAN_SEARCH:
            return {
                ...state,
                loans: action.loans,
                query: action.query,
                error: null
            };
        case types.SEARCH_LOAN_ERROR:
            return { ...state, loans: [], query: null, error: action.error };
        default:
            return state;
    }
}
