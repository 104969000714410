import Oidc from "oidc-client";
import { createUserManager } from "redux-oidc";

const {
    DMS_AUTHORIZATION,
    DMS_CLIENT_ID,
    DMS_REDIRECT_URL,
    DMS_RESOURCE
} = window.runConfig;

const config = {
    client_id: `${DMS_CLIENT_ID}`,
    redirect_uri: `${DMS_REDIRECT_URL}/callback`,
    authority: `${DMS_AUTHORIZATION}/adfs`,
    debug: true,
    response_type: "id_token token",
    post_logout_redirect_uri: `${DMS_REDIRECT_URL}`,
    userStore: new Oidc.WebStorageStateStore(),
    scope: "openid profile",
    resource: `${DMS_RESOURCE}`,
    loadUserInfo: false,
    automaticSlientRenew: true
};

const oidcManager = createUserManager(config);

oidcManager.events.addAccessTokenExpired(() => oidcManager.signinRedirect());

export default oidcManager;
