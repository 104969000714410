import React from "react";
import PropTypes from "prop-types";

export function DocumentListError({ error, retryLoadDocuments }) {
    return (
        <div
            className="document-list__not-viewable document-list__not-viewable--error"
            data-qa="document-list-error"
            data-walkme="document-list-error"
        >
            The loan documents did not load.
            {error && (
                <>
                    <br />
                    {error}
                </>
            )}
            <br />
            <button
                data-qa="retry-button"
                data-walkme="retry-button"
                className="document-list__retry-button ds-button ds-button--cat__secondary ds-button--size__small"
                onClick={retryLoadDocuments}
            >
                Retry loading loan documents
            </button>
        </div>
    );
}

DocumentListError.propTypes = {
    error: PropTypes.string,
    retryLoadDocuments: PropTypes.func
};

export default DocumentListError;
