import userManager from "../utils/userManager";
const authorizedUsers = ["AMC User"];
export async function login() {
    const claims = await userManager.getUser();

    if (claims) {
        return getIdentityClaims(claims.access_token);
    }
    return;
}

export function getIdentityClaims(accessToken) {
    if (accessToken) {
        const encodedClaims = accessToken
            .split(".")[1]
            .replace("-", "+")
            .replace("_", "/");
        const decodedClaimsAsString = atob(encodedClaims);
        const claims = JSON.parse(decodedClaimsAsString);

        /** local: unique_name
         **  dev: firstname and lastname
         **  TODO: Speak with ADFS/Security team to normalize both environments
         **/
        const fullName = claims.unique_name
            ? claims.unique_name
            : claims.firstname + " " + claims.lastname;

        return {
            fullName,
            userId: claims.destiny_id,
            email: claims.email,
            isAuthorized: authorizedUsers.includes(claims.role),
        };
    }

    return {
        fullName: null,
        userId: null,
        email: null,
        isAuthorized: false,
    };
}
